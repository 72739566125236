import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import SelectOrEnter from "../../../components/SelectOrEnter/SelectOrEnter";
import { Tooltip, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "10px 15px"
  },
  gridBox: {
    display: "grid",
    gap: "0"
  },
  inputBox: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  gridWrap: {
    display: "grid",
    width: "100%"
  },
  normalTextField: {
    width: "100%"
  },
  tf_marginRight: {
    marginRight: "1.5rem"
  },
  errorText: {
    color: "red"
  },
  activeAndHelp: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  fontSize: {
    fontSize: "14px"
  }
}));

const Form = ({
  value,
  handleChange
  // handleAdvertiserCodeChange,
  // selectedValue = null,
  // inputValue = "",
  // options = []
}) => {
  const classes = useStyles();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 450
    }
  }))(Tooltip);

  return (
    <>
      <div className="custom-fieldset">
        <form className="formData">
          <div
            className={`flex-box align-items-bottom advertiser-cred-form ${classes.gridBox} ${classes.wrapper}`}
          >
            <FormControl fullWidth className={classes.activeAndHelp}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="is_active"
                    onChange={(e) => handleChange(e)}
                    checked={value.is_active === true ? true : false}
                  />
                }
                label="Active"
              />
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography className={classes.fontSize} color="inherit">
                      <ul>
                        <li>
                          <strong>API Base URL & Body: </strong>
                          {
                            'use "{{{from_date}}}" and "{{{to_date}}}" to specify placeholder for start date and end date respectively.'
                          }
                        </li>
                        <li>
                          <strong>Parameter date format: </strong>

                          {"fill the format of the start date and end date"}
                        </li>
                        <li>
                          <strong>Response nest & Auth response nest: </strong>
                          {
                            "fill the nested format (key) of response data. Follow exact format as in example. Leave null if no nested format"
                          }
                        </li>
                        <li>
                          <strong>Headers: </strong>
                          {
                            "For Auth Based APIs, 'Headers' field is used for initial API call to get auth token."
                          }
                        </li>
                      </ul>
                    </Typography>
                  </React.Fragment>
                }
              >
                <div className="gray-icon ">
                  <HelpIcon fontSize="small" />
                </div>
              </HtmlTooltip>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                variant="standard"
                margin="normal"
                label="API Base URL"
                value={value.url || ""}
                onChange={(e) => handleChange(e)}
                error={value?.errors?.url ? true : false}
                name="url"
                placeholder="E.g: https://test.com?token=a1s2d2&start_date={{{from_date}}}&end_date={{{to_date}}}&format=json"
                className={`${classes.normalTextField}`}
              />
              {value?.errors?.url && (
                <FormHelperText className={classes.errorText}>
                  {value?.errors?.url}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth className={classes.inputBox}>
              <div className={`${classes.gridWrap} ${classes.tf_marginRight}`}>
                {/* <SelectOrEnter
                  label="Select or enter Advertiser code"
                  variant="standard"
                  selectedValue={selectedValue}
                  inputValue={inputValue}
                  options={options}
                  handleAdvertiserCodeChange={handleAdvertiserCodeChange}
                  hasError={value?.errors?.advertiser_code ? true : false}
                  classes={`${classes.normalTextField}`}
                  placeholderText="E.g: lanseraApi, codeFuelGMApi, ..."
                />
                {value?.errors?.advertiser_code && (
                  <FormHelperText className={classes.errorText}>
                    {value?.errors?.advertiser_code}
                  </FormHelperText>
                )} */}

                <TextField
                  variant="standard"
                  margin="normal"
                  label="Response nest"
                  value={value.nesting || ""}
                  onChange={(e) => handleChange(e)}
                  error={value?.errors?.nesting ? true : false}
                  name="nesting"
                  placeholder="Example: data-reports, campaign-0-stats"
                  className={`${classes.normalTextField}`}
                />
                {value?.errors?.nesting && (
                  <FormHelperText className={classes.errorText}>
                    {value?.errors?.nesting}
                  </FormHelperText>
                )}
              </div>
              <div className={classes.gridWrap}>
                <TextField
                  variant="standard"
                  margin="normal"
                  label="Parameter date format"
                  value={value.date_format || ""}
                  onChange={(e) => handleChange(e)}
                  error={value?.errors?.date_format ? true : false}
                  name="date_format"
                  placeholder="Enter date format"
                  className={`${classes.normalTextField}`}
                />
                {value?.errors?.date_format && (
                  <FormHelperText className={classes.errorText}>
                    {value?.errors?.date_format}
                  </FormHelperText>
                )}
              </div>
            </FormControl>
            <FormControl fullWidth className={`${classes.inputBox}`}>
              <div className="form-text-area form-text-area_mr">
                <h4>
                  Headers <small>(Optional)</small>
                </h4>
                <TextField
                  variant="outlined"
                  multiline
                  minRows={4}
                  maxRows={12}
                  value={value.headers || ""}
                  onChange={(e) => handleChange(e)}
                  error={value?.errors?.headers ? true : false}
                  name="headers"
                  placeholder="Enter value in JSON format"
                  className={`${classes.normalTextField}`}
                />
                {value?.errors?.headers && (
                  <FormHelperText className={classes.errorText}>
                    {value?.errors?.headers}
                  </FormHelperText>
                )}
              </div>
              <div className="form-text-area">
                <h4>
                  Body <small>(Optional)</small>
                </h4>
                <TextField
                  variant="outlined"
                  multiline
                  minRows={4}
                  maxRows={12}
                  value={value.body || ""}
                  onChange={(e) => handleChange(e)}
                  error={value?.errors?.body ? true : false}
                  name="body"
                  placeholder="Enter value in JSON format"
                  className={`${classes.normalTextField}`}
                />
                {value?.errors?.body && (
                  <FormHelperText className={classes.errorText}>
                    {value?.errors?.body}
                  </FormHelperText>
                )}
              </div>
            </FormControl>

            {/* mapping fields */}
            <h4>Mapping fields *</h4>
            <FormControl fullWidth className={`${classes.inputBox}`}>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap form-text-area_mr">
                <span>Date: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.date || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={value?.errors?.mapping_fields?.date ? true : false}
                    name="date"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.date && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.date}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap">
                <span>Channel: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.channel || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={value?.errors?.mapping_fields.channel ? true : false}
                    name="channel"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.channel && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.channel}
                    </FormHelperText>
                  )}
                </div>
              </div>
            </FormControl>
            <FormControl fullWidth className={`${classes.inputBox}`}>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap form-text-area_mr">
                <span>Country: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.geo || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={value?.errors?.mapping_fields.geo ? true : false}
                    name="geo"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.geo && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.geo}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap">
                <span>Total Searches: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.total_searches || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={
                      value?.errors?.mapping_fields.total_searches
                        ? true
                        : false
                    }
                    name="total_searches"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.total_searches && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.total_searches}
                    </FormHelperText>
                  )}
                </div>
              </div>
            </FormControl>
            <FormControl fullWidth className={`${classes.inputBox}`}>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap form-text-area_mr">
                <span>Monitized Searches: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.monetized_searches || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={
                      value?.errors?.mapping_fields.monetized_searches
                        ? true
                        : false
                    }
                    name="monetized_searches"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.monetized_searches && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.monetized_searches}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap">
                <span>Clicks: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.clicks || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={value?.errors?.mapping_fields.clicks ? true : false}
                    name="clicks"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.clicks && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.clicks}
                    </FormHelperText>
                  )}
                </div>
              </div>
            </FormControl>
            <FormControl fullWidth className={`${classes.inputBox}`}>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap form-text-area_mr">
                <span>Gross Revenue: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.gross_revenue || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={
                      value?.errors?.mapping_fields.gross_revenue ? true : false
                    }
                    name="gross_revenue"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.gross_revenue && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.gross_revenue}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="form-text-area form-text-area_fifty-fifty form-text-area_linegap">
                <span>Traffic quality: </span>
                <div>
                  <TextField
                    variant="standard"
                    value={value.mapping_fields.tq || ""}
                    onChange={(e) => handleChange(e, true)}
                    error={value?.errors?.mapping_fields.tq ? true : false}
                    name="tq"
                    className={`${classes.normalTextField}`}
                  />
                  {value?.errors?.mapping_fields.tq && (
                    <FormHelperText className={classes.errorText}>
                      {value?.errors?.mapping_fields.tq}
                    </FormHelperText>
                  )}
                </div>
              </div>
            </FormControl>
            <h4>
              For Auth Based APIs <small>(Optional)</small>
            </h4>
            <FormControl fullWidth className={classes.inputBox}>
              <div className={`${classes.gridWrap} ${classes.tf_marginRight}`}>
                <TextField
                  variant="standard"
                  margin="normal"
                  label="Auth url"
                  value={value.auth_url || ""}
                  onChange={(e) => handleChange(e)}
                  error={value?.errors?.auth_url ? true : false}
                  name="auth_url"
                  placeholder="Enter auth url"
                  className={`${classes.normalTextField}`}
                />
                {value?.errors?.auth_url && (
                  <FormHelperText className={classes.errorText}>
                    {value?.errors?.auth_url}
                  </FormHelperText>
                )}
              </div>
              <div className={classes.gridWrap}>
                <TextField
                  variant="standard"
                  margin="normal"
                  label="Auth response nest"
                  value={value.auth_nesting || ""}
                  onChange={(e) => handleChange(e)}
                  error={value?.errors?.auth_nesting ? true : false}
                  name="auth_nesting"
                  placeholder="Example: result-token, token"
                  className={`${classes.normalTextField}`}
                />
                {value?.errors?.auth_nesting && (
                  <FormHelperText className={classes.errorText}>
                    {value?.errors?.auth_nesting}
                  </FormHelperText>
                )}
              </div>
            </FormControl>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
