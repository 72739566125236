import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import Form from "./Form";
import { validJson, validURL } from "../../../helpers/validation";
import { ProviderApiService } from "../../../services/providerApi/index";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const resetForm = {
  url: "",
  auth_url: "",
  date_format: "YYYY-MM-DD",
  body: "",
  headers: "",
  nesting: null,
  auth_nesting: null,
  mapping_fields: {
    geo: "",
    date: "",
    channel: "",
    gross_revenue: "",
    total_searches: "",
    monetized_searches: "",
    clicks: "",
    tq: ""
  },
  is_active: false,
  errors: {
    url: "",
    auth_url: "",
    date_format: "",
    headers: "",
    body: "",
    nesting: null,
    auth_nesting: null,
    mapping_fields: {
      geo: "",
      date: "",
      channel: "",
      gross_revenue: "",
      total_searches: "",
      monetized_searches: "",
      clicks: "",
      tq: ""
    }
  }
};

const CredentialsForm = (props) => {
  const { provider_id } = props;
  const history = useHistory();
  const [formValues, setFormValues] = useState(resetForm);

  const mapformValues = (data) => {
    return {
      url: data.url,
      auth_url: data.auth_url,
      date_format: data.date_format,
      headers: data.headers,
      nesting: data.nesting,
      auth_nesting: data.auth_nesting,
      body: data.body,
      mapping_fields: data.mapping_fields,
      is_active: data.is_active,
      errors: {
        url: "",
        auth_url: "",
        date_format: "",
        headers: "",
        body: "",
        nesting: null,
        auth_nesting: null,
        mapping_fields: {
          geo: "",
          date: "",
          channel: "",
          gross_revenue: "",
          total_searches: "",
          monetized_searches: "",
          clicks: "",
          tq: ""
        }
      }
    };
  };

  const getProviderCredentials = useCallback(async () => {
    try {
      const response = await ProviderApiService.getProviderCredentialsV2(
        provider_id
      );

      if (response.success) {
        if (response.data) {
          const mappedFormValues = mapformValues(response.data);
          setFormValues(mappedFormValues);
        }
      } else {
        toastr.error("error", response?.error?.message);
      }
    } catch (error) {
      toastr.error("Opps", error.message || error);
    }
  }, [provider_id]);

  const updateErrorState = (data, name, msg = "", mappingFields = false) => {
    let newDataSet;
    if (mappingFields) {
      newDataSet = {
        ...data,
        errors: {
          ...data.errors,
          mapping_fields: {
            ...data.errors.mapping_fields,
            [name]: msg
          }
        }
      };
    } else {
      newDataSet = {
        ...data,
        errors: {
          ...data.errors,
          [name]: msg
        }
      };
    }
    return newDataSet;
  };

  const isValidNestFormat = (str) => {
    const regex = /^[A-Za-z0-9-_]*$/;
    return regex.test(str);
  };

  const validateBeforeSubmit = (dataToValidate) => {
    let proceed = true;

    if (!dataToValidate.url) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "url",
        "API Base Url is required"
      );
      proceed = false;
    } else if (dataToValidate.url && !validURL(dataToValidate.url)) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "url",
        "API Base Url is not valid url"
      );
      proceed = false;
    }
    if (dataToValidate.auth_url && !validURL(dataToValidate.auth_url)) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "auth_url",
        "Auth Url is not valid url"
      );
      proceed = false;
    }
    if (dataToValidate.auth_nesting) {
      if (!dataToValidate.auth_url) {
        dataToValidate = updateErrorState(
          dataToValidate,
          "auth_nesting",
          "Cannot enter value when Auth url is empty."
        );
        proceed = false;
      } else if (!isValidNestFormat(dataToValidate.auth_nesting)) {
        dataToValidate = updateErrorState(
          dataToValidate,
          "auth_nesting",
          "Invalid format"
        );
        proceed = false;
      }
    }
    if (!dataToValidate.date_format) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "date_format",
        "Date format is required"
      );
      proceed = false;
    }
    if (dataToValidate.params && !validJson(dataToValidate.params)) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "params",
        "Not a valid JSON format"
      );
      proceed = false;
    }
    if (dataToValidate.headers && !validJson(dataToValidate.headers)) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "headers",
        "Not a valid JSON format"
      );
      proceed = false;
    }
    if (dataToValidate.body && !validJson(dataToValidate.body)) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "body",
        "Not a valid JSON format"
      );
      proceed = false;
    }

    // validate mapping fields
    if (!dataToValidate.mapping_fields.date) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "date",
        "Date is required",
        true
      );
      proceed = false;
    }
    if (!dataToValidate.mapping_fields.channel) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "channel",
        "Channel is required",
        true
      );
      proceed = false;
    }
    if (!dataToValidate.mapping_fields.geo) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "geo",
        "Country is required",
        true
      );
      proceed = false;
    }
    if (!dataToValidate.mapping_fields.total_searches) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "total_searches",
        "Total Searches is required",
        true
      );
      proceed = false;
    }
    if (!dataToValidate.mapping_fields.monetized_searches) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "monetized_searches",
        "Monetized Searches is required",
        true
      );
      proceed = false;
    }
    if (!dataToValidate.mapping_fields.clicks) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "clicks",
        "Clicks is required",
        true
      );
      proceed = false;
    }
    if (!dataToValidate.mapping_fields.gross_revenue) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "gross_revenue",
        "Gross Revenue is required",
        true
      );
      proceed = false;
    }
    if (dataToValidate.nesting && !isValidNestFormat(dataToValidate.nesting)) {
      dataToValidate = updateErrorState(
        dataToValidate,
        "nesting",
        "Invalid format"
      );
      proceed = false;
    }

    setFormValues(dataToValidate);

    return proceed;
  };

  const submitData = async () => {
    try {
      const payload = formValues;
      setFormValues(payload);

      if (validateBeforeSubmit(payload)) {
        delete payload.errors;

        const response = await ProviderApiService.addProviderCredentialsV2(
          payload,
          provider_id
        );
        if (response.success) {
          toastr.success("Success", "API credentials added successfully!");
          history.push(`/advertisers`);
        } else {
          throw new Error(response?.error?.message);
        }
      }
    } catch (error) {
      toastr.error("Opps", error.message || error);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    history.push("/advertisers");
  };

  const handleValueChange = (event, mappingField = false) => {
    const key = event.target.name;
    const value =
      event.target.name === "is_active"
        ? event.target.checked
        : event.target.value;

    if (mappingField) {
      setFormValues((values) => ({
        ...values,
        mapping_fields: {
          ...values.mapping_fields,
          [key]: value
        },
        errors: {
          ...values.errors,
          mapping_fields: {
            ...values.errors.mapping_fields,
            [key]: ""
          }
        }
      }));
    } else {
      setFormValues((values) => ({
        ...values,
        [key]: value,
        errors: {
          ...values.errors,
          [key]: ""
        }
      }));
    }
  };

  useEffect(() => {
    if (provider_id) {
      getProviderCredentials();
    }
  }, [getProviderCredentials, provider_id]);

  return (
    <React.Fragment>
      <Form value={formValues} handleChange={handleValueChange} />
      <div className="form__controls">
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={(event) => submitData(event)}
          startIcon={<CheckIcon />}
          type="submit"
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<ClearIcon />}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

export default CredentialsForm;
