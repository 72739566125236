import { useState, useEffect } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { REVENUE_TABLE_HEADERS } from "../../helpers/constant/localStorage";
import {
  useRevColHeadersContext,
  updateRevColHeaders
} from "../../contexts/revenue-context";
import {
  ADVERTISER_REPORT,
  RULE_ID,
  TAG_ID,
  CHANNEL,
  AD_COVERAGE,
  PUBLISHER_REVENUE,
  PUBLISHER_REPORT,
  DATE,
  GEO,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  // CTR,
  GROSS_REVENUE,
  NET_REVENUE,
  RPM,
  RPMM,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES,
  SEARCH_ENGINE,
  LINK_PLATFORM,
  TAG_TYPE,
  RPC,
  PUBLISHER_AD_ACCOUNT,
  SERVER_SEARCHES_RPM,
  DIVIDED_PERCENTAGE,
  REMARKS,
  PUBLISHER_CHANNEL_ID,
  PUBLISHER_ID,
  FILTERED_REASON,
  REDIRECT_FROM_PUBLISHER_CHANNEL_ID,
  OVERCAPPED_TRAFFIC,
  // ESTIMATED_REVENUE,
  // BUFFER,
  ADVERTISER_LINK_GROUP_ID,
  TRAFFIC_QUALITY
} from "../../helpers/constant/filters";
import { table_mapping_order } from "../../helpers/constant/mapping";

function getInitialShowState() {
  let showState = {};
  showState[ADVERTISER_REPORT] = true;
  showState[RULE_ID] = true;
  showState[TAG_ID] = true;
  showState[CHANNEL] = true;
  showState[AD_COVERAGE] = true;
  showState[PUBLISHER_REVENUE] = true;
  showState[PUBLISHER_AD_ACCOUNT] = true;
  showState[PUBLISHER_REPORT] = true;
  showState[DATE] = true;
  showState[CHANNEL] = true;
  showState[GEO] = true;
  showState[TOTAL_SEARCHES] = true;
  showState[MONETIZED_SEARCHES] = true;
  showState[CLICKS] = true;
  // showState[CTR] = true;
  showState[GROSS_REVENUE] = true;
  showState[NET_REVENUE] = true;
  // showState[ESTIMATED_REVENUE] = true;
  // showState[BUFFER] = true;
  showState[RPM] = true;
  showState[RPMM] = true;
  showState[RPC] = true;
  showState[SERVER_SEARCHES_RPM] = true;
  showState[FOLLOWON_SEARCHES] = true;
  showState[INITIAL_SEARCHES] = true;
  showState[SEARCH_ENGINE] = true;
  showState[LINK_PLATFORM] = true;
  showState[TAG_TYPE] = true;
  showState["sno"] = true;
  showState["server_search_counts"] = true;
  showState["search_count"] = true;
  showState[REMARKS] = true;
  showState[DIVIDED_PERCENTAGE] = true;
  showState[PUBLISHER_ID] = true;
  showState[PUBLISHER_CHANNEL_ID] = true;
  showState[ADVERTISER_LINK_GROUP_ID] = true;
  showState["ad_coverage_server_percentage"] = true;
  showState["discrepancy_percentage"] = true;
  showState[REDIRECT_FROM_PUBLISHER_CHANNEL_ID] = true;
  showState["filtered"] = true;
  showState[FILTERED_REASON] = true;
  showState["invalid_traffic"] = true;
  showState[OVERCAPPED_TRAFFIC] = true;
  showState[TRAFFIC_QUALITY] = true;
  return showState;
}

export const useReportTableFilterLogic = ({ displayNames, list }) => {
  const revHeadersCtxValues = useRevColHeadersContext(); // Use Revenue Headers Context
  const revHeadersCtxDispatch = revHeadersCtxValues[1];
  const [tableHeaders, setTableHeaders] = useState([]);
  const [filteredTableHeaders, setFilteredTableHeaders] = useState([]);
  const [showState, setShowState] = useLocalStorage(
    REVENUE_TABLE_HEADERS,
    getInitialShowState()
  );

  const updateHeadersShowValue = (value, index) => {
    let newShowState = { ...showState };
    newShowState[value] = !newShowState[value];
    setShowState(newShowState);
  };

  /** updating table headers when display names and data changes */
  useEffect(() => {
    /**
     *  Function that returns an object of column headers from displayNames
     *  to be used in Table
     */
    function getColumnHeaders(displayNames) {
      let columnHeaders = {};
      Object.keys(displayNames).forEach((columnValue) => {
        columnHeaders[columnValue] = {
          show: showState[columnValue],
          title: displayNames[columnValue],
          value: columnValue
        };
      });
      return columnHeaders;
    }

    // Remaining Table Column not mapped to display names
    function groupByValueMappedToHeader(value) {
      switch (value) {
        case ADVERTISER_REPORT:
          return {
            title: "Advertiser",
            value: ADVERTISER_REPORT,
            show: showState[ADVERTISER_REPORT]
          };
        case RULE_ID:
          return { title: "Rule Id", value: RULE_ID, show: showState[RULE_ID] };
        case TAG_ID:
          return {
            title: "Pub Link Id",
            value: TAG_ID,
            show: showState[TAG_ID]
          };
        case REMARKS:
          return {
            title: "Remarks",
            value: "remarks",
            show: showState["remarks"]
          };
        case DIVIDED_PERCENTAGE:
          return {
            title: "Divided Percentage",
            value: "divided_percentage",
            show: showState["divided_percentage"]
          };
        case PUBLISHER_AD_ACCOUNT:
          return {
            title: "Publisher Ad Account",
            value: PUBLISHER_AD_ACCOUNT,
            show: showState[PUBLISHER_AD_ACCOUNT]
          };
        case CHANNEL:
          return { title: "Channel", value: CHANNEL, show: showState[CHANNEL] };
        case AD_COVERAGE:
          return {
            title: "Ad Coverage (%)",
            value: AD_COVERAGE,
            show: showState[AD_COVERAGE]
          };
        case PUBLISHER_REVENUE:
          return {
            title: "Publisher Revenue",
            value: PUBLISHER_REVENUE,
            show: showState[PUBLISHER_REVENUE]
          };
        // case ESTIMATED_REVENUE:
        //   return {
        //     title: "Estimated Gross Revenue",
        //     value: ESTIMATED_REVENUE,
        //     show: showState[ESTIMATED_REVENUE]
        //   };
        // case BUFFER:
        //   return {
        //     title: "Buffer",
        //     value: BUFFER,
        //     show: showState[BUFFER]
        //   };
        case "server_search_counts":
          return {
            title: "Server Search Count",
            value: "search_count",
            show: showState["server_search_counts"]
          };
        case "invalid_traffic":
          return {
            title: "Invalid Traffic",
            value: "invalid_traffic",
            show: showState["invalid_traffic"]
          };
        case OVERCAPPED_TRAFFIC: {
          return {
            title: "Overcapped Traffic",
            value: OVERCAPPED_TRAFFIC,
            show: showState[OVERCAPPED_TRAFFIC]
          };
        }
        case SEARCH_ENGINE:
          return {
            title: "Search Engine",
            value: SEARCH_ENGINE,
            show: showState[SEARCH_ENGINE]
          };
        case LINK_PLATFORM:
          return {
            title: "Link Platform",
            value: LINK_PLATFORM,
            show: showState[LINK_PLATFORM]
          };
        case TAG_TYPE:
          return {
            title: "Traffic Type",
            value: TAG_TYPE,
            show: showState[TAG_TYPE]
          };
        case SERVER_SEARCHES_RPM:
          return {
            title: "Server Searches RPM",
            value: SERVER_SEARCHES_RPM,
            show: showState[SERVER_SEARCHES_RPM]
          };
        case ADVERTISER_LINK_GROUP_ID:
          return {
            title: "Adv Link Group",
            value: ADVERTISER_LINK_GROUP_ID,
            show: showState[ADVERTISER_LINK_GROUP_ID]
          };
        case "publisher_id":
          return {
            title: "Publisher",
            value: "publisher_id",
            show: showState["publisher_id"]
          };
        case "discrepancy_percentage":
          return {
            title: "Discrepancy",
            value: "discrepancy_percentage",
            show: showState["discrepancy_percentage"]
          };
        case "ad_coverage_server_percentage":
          return {
            title: "Ad Coverage Server",
            value: "ad_coverage_server_percentage",
            show: showState["ad_coverage_server_percentage"]
          };
        case "redirect_from_publisher_channel_id":
          return {
            title: "Redirect From",
            value: "redirect_from_publisher_channel_id",
            show: showState["redirect_from_publisher_channel_id"]
          };
        // case "provider_link_id":
        //     return {
        //         title: "Advertiser link id",
        //         value: "provider_link_id",
        //         show: showState[PROVIDER_LINK_ID]
        //     };
        case "filtered":
          return {
            title: "Filtered",
            value: "filtered",
            show: showState["filtered"]
          };
        case "filtered_reason":
          return {
            title: "Filtered Reason",
            value: "filtered_reason",
            show: showState["filtered_reason"]
          };
        case "publisher_channel_id":
          return {
            title: "Pub Link Id",
            value: "publisher_channel_id",
            show: showState["publisher_channel_id"]
          };
        // case "quality_score":
        //     return {
        //         title: "Quality score",
        //         value: "quality_score",
        //         show: showState[QUALITY_SCORE]
        //     };
        case TRAFFIC_QUALITY:
          return {
            title: "Traffic Quality",
            value: TRAFFIC_QUALITY,
            show: showState[TRAFFIC_QUALITY]
          };
        default:
          return "";
      }
    }

    //function to get headers based on columns state whose show value are set to true
    function getTableHeaders(tableColumns, list) {
      let headers = [{ title: "SNo", value: "sno", show: showState["sno"] }];
      let oneValueFromList = list?.[1] || {};
      let keysFromValue = Object.keys(oneValueFromList);
      console.log({ keysFromValue });
      table_mapping_order.forEach((value) => {
        if (keysFromValue.includes(value)) {
          let header = {};
          if (value in tableColumns) {
            header = tableColumns[value];
          } else {
            header = groupByValueMappedToHeader(value);
          }
          if (header) {
            headers.push(header);
          }
        }
      });
      return headers;
    }

    if (displayNames && list && list?.length > 0) {
      let tableColumns = getColumnHeaders(displayNames.fields);
      let tableHeaders = getTableHeaders(tableColumns, list);
      setTableHeaders(tableHeaders);
    }
  }, [displayNames, list, showState]);

  useEffect(() => {
    setTableHeaders((tableHeaders) =>
      tableHeaders.map((header) => {
        header.show = showState[header.value];
        return header;
      })
    );
  }, [showState]);

  useEffect(() => {
    setFilteredTableHeaders(tableHeaders.filter((header) => header.show));
    updateRevColHeaders(revHeadersCtxDispatch, tableHeaders);
    // eslint-disable-next-line
  }, [tableHeaders]);

  return {
    tableHeaders,
    filteredTableHeaders,
    updateHeadersShowValue
  };
};
