import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
//
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Typography from "@material-ui/core/Typography";
//
import Table from "../../components/Table/Table";
import { AdvertiserLinkGroup } from "../../services/AdvertiserLinkGroup";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import TargetFilterBox from "./layouts/TargetFilterBox";
// import TargetBulkAction from "./layouts/TargetBulkAction";
import SimpleAccordion from "../../components/Accordion/Accordion";
import { textFieldValid } from "../../helpers/validation";
import TabMenu from "../../components/TabMenu/TabMenu";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import {
  //   updateTargetingForm,
  updateTargetingLists
} from "../../reducers/target/target.action";
import {
  getSelectedFilter,
  getTargetings
} from "../../reducers/target/target.selector";
import { copyToClipboard } from "../../helpers/index";
// import { LAST_SEVEN_DAYS, PER_DAY } from "../../helpers/constant/filters";
import ConnectedAdvertiserLinksModal from "../AdvertiserLinkGroup/layouts/ConnectedAdvertiserLinksModal";
import ConnectedPublisherLinksModal from "./layouts/ConnectedPublisherLinksModal";

const List = (props) => {
  const targetings = useSelector(getTargetings);
  const history = useHistory();

  const headers = [
    { title: "Group ID", value: "id" },
    { title: "Description", value: "description" },
    { title: "Published", value: "published" },
    { title: "Active", value: "active" },
    { title: "Last Updated", value: "updated_at" },
    { title: "Capacity", value: "capacity" },
    { title: "Remaining Capacity", value: "remaining_capacity" }
  ];
  const headersForArchive = [
    { title: "Group ID", value: "id" },
    { title: "Archived At (UTC) ", value: "updatedAt" }
  ];
  //selection index to choose between Archived and Normal Publishers List
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const sections = [
    {
      name: "Advertiser Link Groups"
    },
    {
      name: "Archived Advertiser Link Groups"
    }
  ];
  let choiceOfWord =
    currentSelectedSectionIndex === 0 ? "archive" : "delete forever";
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  //state for modal
  const [open, setOpen] = useState(false);
  const [unArchiveOpen, setUnArchiveOpen] = useState(false);
  //state for filter box
  const selectedFilter = useSelector(getSelectedFilter);
  const [searchValue, setSearchValue] = useState(selectedFilter.query);
  const [published, setPublished] = useState(selectedFilter.published);
  const [draft, setDraft] = useState(selectedFilter.draft);
  const [is_live, setIsLive] = useState(selectedFilter.isLive);
  const [tempProviderLinks, setTempProviderLinks] = useState([]);
  const [tempPublisherLinks, setTempPublisherLinks] = useState([]);
  const [tempId, setTempId] = useState(null);
  const [tag, setTag] = useState(selectedFilter.tagId);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
  const [total, setTotal] = useState(0);
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1); //!!IMP : SET IT SAME AS DEFAULT VALUE FOR PAGE STATE
  const getRequestParams = useCallback(() => {
    let params = {};

    if (textFieldValid(searchValue)) {
      params["q"] = searchValue.trim();
    }

    if (published) {
      params["published"] = true;
    }

    if (archived) {
      params["archived"] = true;
    }

    if (draft) {
      params["published"] = "false";
    }

    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;

    if (orderBy === "Publisher") {
      params["order_by"] = "publisher_id";
    }

    params["order_direction"] = orderDirection;

    return params;
  }, [
    draft,
    orderDirection,
    orderBy,
    page,
    size,
    published,
    searchValue,
    archived
  ]);
  const fetchTargeting = useCallback(
    (params = {}) => {
      setLoading(true);
      AdvertiserLinkGroup.getList(params)
        .then((response) => {
          setTotal(response.data.total);
          stableDispatch(
            updateTargetingLists(response.data.advertiser_link_groups)
          );
          setLoading(false);
        })
        .catch((error) => {
          console.trace(error.message);
          stableDispatch(updateTargetingLists([]));
          toastr.error("Error", "No data found");
          setLoading(false);
        });
    },
    [stableDispatch]
  );
  useEffect(() => {
    fetchTargeting(getRequestParams());
    prevPage.current === page && setSelected([]); //page changed don't make the selected rows empty
    if (page !== prevPage.current) {
      prevPage.current = page;
    }
  }, [
    draft,
    page,
    published,
    archived,
    size,
    orderBy,
    is_live,
    orderDirection,
    getRequestParams,
    fetchTargeting
  ]);

  //   const getPublisherIDOfTargeting = (id) => {
  //     let targetingIndex = targetings.findIndex(
  //       (targeting) => targeting.id === id
  //     );
  //     let publisher_id = targetings[targetingIndex].publisher_id;
  //     return publisher_id;
  //   };

  const handleModalClose = () => {
    setChangeID(null);
    setOpen(false);
    setUnArchiveOpen(false);
  };
  const handleConnectedAdCloseAction = () => {
    setTempProviderLinks([]);
  };

  const handleConnectPublisherLinkCloseAction = () => {
    setTempPublisherLinks([]);
  };

  //publisher id to change through modal
  const [changeID, setChangeID] = useState(null);
  const [selected, setSelected] = React.useState([]);

  /*archived and normal data selection change handler */
  const handleSelectionIndexChange = (index) => {
    //setting Page to 1
    setPage(1);
    setSelected([]);
    setCurrentSelectedSectionIndex(index);
    if (index === 0) {
      //normal publisher
      setArchived(false);
    } else {
      setArchived(true);
    }
  };

  const handleEditAction = (id) => {
    // if (!checkDefaultDomainIsSet()) return;
    history.push(`/advertiser-link-group/edit/${id}`);
  };

  //   const handleRevReportlLinkAction = (id) => {
  //     const urlWithParams = `${window.location.origin}/revenuereport?pub_link_id=${id}&interval=${PER_DAY}&period=${LAST_SEVEN_DAYS}&group_by=publisher_id-publisher_channel_id`;
  //     window.open(urlWithParams, "_blank");
  //   };

  const handleDeleteAction = (id) => {
    setChangeID(id);
    setOpen(true);
  };

  const onConnectedButtonClick = (id, data) => {
    if (
      data.advertiser_link_group_rules &&
      data.advertiser_link_group_rules.length > 0
    ) {
      setTempProviderLinks(data.advertiser_link_group_rules);
      setTempId(id);
    }
  };

  const onConnectedPublisherButtonClick = (id, data) => {
    if (data.publisher_links && data.publisher_links.length > 0) {
      setTempPublisherLinks(data.publisher_links);
      setTempId(id);
    }
  };

  const deleteTargeting = () => {
    AdvertiserLinkGroup.delete(changeID)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchTargeting(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Archived Successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.log({ error });
        console.trace(error.message);
        toastr.error("Oops", "Failed to archive");
        setOpen(false);
      });
  };

  const unArchiveTargeting = () => {
    // eslint-disable-next-line no-unused-vars
    let params = {
      archive: false
    };
    AdvertiserLinkGroup.restore(changeID)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Advertiser link group un-archived!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to un-archive the targeting");
      });
  };

  const handleRestoreAction = (id) => {
    setChangeID(id);
    setUnArchiveOpen(true);
  };

  const handleDeleteForever = () => {
    let params = {
      permanent: true
    };
    AdvertiserLinkGroup.delete(changeID, params)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchTargeting(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Targeting removed permanently!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops", "Not able to remove at the moment");
        setOpen(false);
      });
    setChangeID(null);
    setOpen(false);
  };

  const handleCopyTagToClipboard = (id) => {
    let targeting = targetings.find((targeting) => targeting.id === id);
    copyToClipboard(targeting.link);
    toastr.success("Success", "Link copied!");
  };

  //action list for archived table
  const actionsForArchive = [
    // {
    //   name: "deleteForever",
    //   action: handleDeleteAction,
    //   icon: "deleteForever"
    // },
    {
      name: "restore",
      action: handleRestoreAction,
      icon: "restore"
    }
  ];

  const actions = [
    {
      name: "edit",
      action: handleEditAction,
      icon: "editIcon"
    },
    {
      name: "delete",
      action: handleDeleteAction,
      icon: "deleteIcon"
    },
    {
      name: "connectedAdvertiserLinks",
      action: onConnectedButtonClick,
      icon: "connectedAdvertiserLinks"
    },
    {
      name: "connectedPublisherLinks",
      action: onConnectedPublisherButtonClick,
      icon: "connectedPublisherLinks"
    }
  ];
  const icon = <TargetIcon fontSize="inherit" color="primary" />;

  const deleteTargetingModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        <Typography component="h3">
          Are you sure you want to {choiceOfWord}?
        </Typography>
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              //current Selection is 0 i.e normal data, archive
              if (currentSelectedSectionIndex === 0) deleteTargeting();
              else handleDeleteForever();
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const unArchiveModal = (
    <ConfirmBox
      message={`Are you sure you want to un-archive this advertiser link?`}
      denyAction={handleModalClose}
      confirmAction={() => {
        unArchiveTargeting();
        handleModalClose();
      }}
    ></ConfirmBox>
  );

  const openTargetFormPage = () => {
    history.push("/advertiser-link-group/add");
  };

  return (
    <div className="targetings-container">
      <div className="targetings-container__header">
        <ContentHeader icon={icon} title="Advertiser Link Group List" />
        <div className="targetings-container__addBtn">
          <Button
            onClick={openTargetFormPage}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="targetings-container__filter-box">
        <SimpleAccordion
          header="Filters"
          key="targeting-filterbox"
          expanded={
            selectedFilter.query ||
            selectedFilter.isLive !== null ||
            selectedFilter.published ||
            selectedFilter.draft ||
            false
          }
        >
          <TargetFilterBox
            setSearchValue={setSearchValue}
            published={published}
            setPublished={setPublished}
            draft={draft}
            setDraft={setDraft}
            is_live={is_live}
            setIsLive={setIsLive}
            setSelected={setSelected}
            archive={currentSelectedSectionIndex === 1}
            tag={tag}
            setTag={setTag}
          />
        </SimpleAccordion>
      </div>
      <div className="table-selection-tab">
        <TabMenu
          sections={sections}
          setCurrentSelectedSectionIndex={handleSelectionIndexChange}
          currentSelectedSectionIndex={currentSelectedSectionIndex}
        />
      </div>
      <Table
        headers={
          currentSelectedSectionIndex === 0 ? headers : headersForArchive
        }
        rowValues={targetings}
        actions={
          currentSelectedSectionIndex === 0 ? actions : actionsForArchive
        }
        selected={selected}
        setSelected={setSelected}
        tableType={
          currentSelectedSectionIndex === 0
            ? "advertiser-link-group"
            : "advertiser-link-group--archive"
        }
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        total={total}
        isLoading={loading}
        rowsPerPageOptions={[10, 50, 100, 150, 200]}
        handleEditAction={handleEditAction}
        hoverButtonAction={handleCopyTagToClipboard}
        hoverButtonMsg={"Copy Link"}
      />
      {unArchiveOpen && unArchiveModal}
      {open && deleteTargetingModal}
      {tempProviderLinks && tempProviderLinks.length > 0 && (
        <ConnectedAdvertiserLinksModal
          targetLinkId={tempId}
          providerLinkObj={tempProviderLinks}
          closeAction={handleConnectedAdCloseAction}
        />
      )}
      {tempPublisherLinks && tempPublisherLinks.length > 0 && (
        <ConnectedPublisherLinksModal
          targetLinkId={tempId}
          providerLinkObj={tempPublisherLinks}
          closeAction={handleConnectPublisherLinkCloseAction}
        />
      )}
    </div>
  );
};

export default List;
