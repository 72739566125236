//Icons used in table actions column
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookIcon from "@material-ui/icons/MenuBook"; //icon for managing publisher targeting
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { FileCopyTwoTone } from "@material-ui/icons";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";
import CloseIcon from "@material-ui/icons/Close";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InfoIcon from "@material-ui/icons/Info";
import EyeIcon from "@material-ui/icons/Visibility";
import SnoozeIcon from "@material-ui/icons/Snooze";
import DoneIcon from "@material-ui/icons/Done";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import HistoryIcon from "@material-ui/icons/History";
import CodeIcon from "@material-ui/icons/Code";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

export const getActionIcons = (iconName, data = null) => {
  switch (iconName) {
    case "editIcon":
      return (
        <Tooltip title="Edit">
          <IconButton color="primary">
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    case "TargetinglinkIcon":
      return (
        <Tooltip title="View Targetings">
          <IconButton color="primary">
            <AllInclusiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "redirectIcon":
      return (
        <Tooltip title="Redirect To Advertiser">
          <IconButton color="primary">
            <FlipToBackIcon />
          </IconButton>
        </Tooltip>
      );
    case "TargetinglinkIconDisabled":
      return (
        <Tooltip title="No Targetings">
          <IconButton>
            <AllInclusiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "deleteIcon":
      return (
        <Tooltip title="Archive">
          <IconButton className="yellow-icon">
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "delete":
      return (
        <Tooltip title="Delete">
          <IconButton className="red-icon">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    case "deleteForever":
      return (
        <Tooltip title="Delete Forever">
          <IconButton color="secondary">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    case "removeReport":
      return (
        <Tooltip title="Remove Report">
          <IconButton className="yellow-icon">
            <DeleteSweepIcon />
          </IconButton>
        </Tooltip>
      );
    case "duplicateIcon":
      return (
        <Tooltip title="Duplicate">
          <IconButton color="primary">
            <FileCopyTwoTone />
          </IconButton>
        </Tooltip>
      );
    case "createPublisherLink":
      return (
        <Tooltip title="Create publisher link">
          <span style={{ fontSize: "1.2rem" }} color="primary">
            P+
          </span>
          {/* P +<IconButton color="primary"><InsertLink /></IconButton> */}
        </Tooltip>
      );
    case "manageIconOne":
      return (
        <Tooltip title="Manage Links">
          <IconButton color="primary">
            <AllInclusiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "manageIconTwo":
      return (
        <Tooltip title="Manage">
          <IconButton color="inherit">
            <MenuBookIcon />
          </IconButton>
        </Tooltip>
      );
    case "deleteRevenueLog":
      return (
        <Tooltip title="Delete revenue logs">
          <IconButton style={{ color: "red" }}>
            <DeleteSweepIcon />
          </IconButton>
        </Tooltip>
      );
    case "copyToClipboardIcon":
      return (
        <Tooltip title="Copy Link">
          <IconButton className="green-icon">
            <AssignmentTurnedInIcon />
          </IconButton>
        </Tooltip>
      );
    case "restore":
      return (
        <Tooltip title="Unarchive">
          <IconButton className="green-icon">
            <UnarchiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "redirectToRevenue":
      return (
        <Tooltip title="Redirect To Revenue">
          <IconButton className="blue-icon">
            <AttachMoneyIcon />
          </IconButton>
        </Tooltip>
      );
    case "details":
      return (
        <Tooltip title="Details">
          <IconButton className="blue-icon">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      );
    case "linkIcon":
      return (
        <Tooltip title="View Publisher's Dashboard">
          <IconButton className="blue-icon">
            <EyeIcon />
          </IconButton>
        </Tooltip>
      );
    case "disabledLinkIcon":
      return (
        <Tooltip title="User not available">
          <IconButton>
            <EyeIcon />
          </IconButton>
        </Tooltip>
      );
    case "display-column":
      return (
        <Tooltip title="Select Display Columns">
          <IconButton>
            <TextFieldsIcon />
          </IconButton>
        </Tooltip>
      );
    case "advertiserLinks-related":
      return (
        <Tooltip
          title={`${
            data.no_of_active_rules > 0
              ? "Display Related Rule and Link"
              : data.no_of_rules > 0
              ? "No active linked rules found"
              : "No Related Rule and Link"
          }`}
        >
          <IconButton
            style={{
              color:
                data.no_of_active_rules > 0
                  ? "var(--color-primary)"
                  : data.no_of_rules > 0
                  ? "orange"
                  : "default"
            }}
          >
            <TargetIcon />
          </IconButton>
        </Tooltip>
      );
    case "advertiserLinks-delete":
      return (
        <Tooltip title="Delete Link" className="provider-link__close">
          <IconButton color={true ? "secondary" : "default"}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      );
    case "snoozeIcon":
      return (
        <Tooltip title="Snooze">
          <span>
            <IconButton
              color="primary"
              disabled={
                ["snoozed", "closed"].includes(data.status) ? true : false
              }
            >
              <SnoozeIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    case "closeIcon":
      return (
        <Tooltip title="Close">
          <span>
            <IconButton
              color="primary"
              disabled={["closed"].includes(data.status) ? true : false}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    case "activeIcon":
      return (
        <Tooltip title="Active">
          <span>
            <IconButton color="primary">
              <DoneIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    case "MonetizationIcon":
      return (
        <Tooltip title="Revenue Report">
          <span>
            <IconButton className="green-icon">
              <MonetizationOnIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    case "skypeCode":
      return (
        <Tooltip title="Generate and copy code for Skype">
          <span>
            <IconButton color="primary">
              <CodeIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    case "removeSkypeConversation":
      return (
        <Tooltip title="Remove Skype conversation ID">
          <span>
            <IconButton style={{ color: "red" }}>
              <RemoveCircleIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    case "HistoryIcon":
      return (
        <Tooltip title="Latest history">
          <span>
            <IconButton color="primary">
              <HistoryIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    case "connectedAdvertiserLinks":
      return (
        <Tooltip title="Connected advertiser links">
          <IconButton style={{ color: "var(--color-primary)" }}>
            <TargetIcon />
          </IconButton>
        </Tooltip>
      );
    case "connectedPublisherLinks":
      return (
        <Tooltip title="Connected publisher links">
          <IconButton style={{ color: "var(--color-primary-opaque)" }}>
            <TargetIcon />
          </IconButton>
        </Tooltip>
      );
    default:
      return <div></div>;
  }
};
