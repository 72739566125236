import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Publisher } from "../../../services/Publisher";
import { TagType } from "../../../services/TagType";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilter } from "../../../reducers/target/target.action";
import { getSelectedFilter } from "../../../reducers/target/target.selector";

const TargetFilterBox = ({
  setSearchValue,
  published,
  setPublished,
  draft,
  is_live,

  setIsLive,
  publisher,
  setPublisher,
  trafficType,
  setTrafficType,
  setDraft,
  archive = false,
  setSelected,
  setTag,
  didnotReachCap,
  setDidnotReachCap,
  didnotGoLive,
  setDidnotGoLive,
  trafficPaused,
  setTrafficPaused
}) => {
  const selectedFilter = useSelector(getSelectedFilter);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [publishers, setPublishers] = useState(null);
  const [tagId, setTagId] = useState(selectedFilter.tagId);
  const [query, setQuery] = useState(selectedFilter.query);
  const [trafficTypes, setTrafficTypes] = useState(null);

  const handleSearchButton = () => {
    setSearchValue(query);
    setTag(tagId);
    setSelected([]);

    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        tagId,
        query
      })
    );
  };

  const fetchPublishers = () => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            publishers.push({ label: p.name, value: p.id });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  };

  const fetchTrafficTypes = () => {
    TagType.fetchTagTypes({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    }).then((response) => {
      if (response.success) {
        const data = response.data.tag_types;
        const trafficTypes = [];
        for (let p of data) {
          trafficTypes.push({ label: p.name, value: p.id });
        }
        setTrafficTypes(trafficTypes);
      } else {
        throw new Error(JSON.stringify(response.error));
      }
    });
  };

  useEffect(() => {
    fetchPublishers();
    fetchTrafficTypes();
  }, []);

  const handleChange = (type) => {
    let isPublished, isDraft;
    if (type === "published") {
      if (!published) {
        setDraft(false);
        isDraft = false;
      }
      isPublished = !published;
      setPublished(!published);
    } else {
      if (!draft) {
        setPublished(false);
        isPublished = false;
      }
      isDraft = !draft;
      setDraft(!draft);
    }
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        draft: isDraft,
        published: isPublished
      })
    );
  };

  const handleLiveChange = (type) => {
    let isLive;
    if (type === "live") {
      if (is_live === true) {
        setIsLive(null);
        isLive = null;
      } else {
        setIsLive(true);
        isLive = true;
      }
    } else {
      if (is_live === false) {
        setIsLive(null);
        isLive = null;
      } else {
        setIsLive(false);
        isLive = false;
      }
    }
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        isLive
      })
    );
  };

  const handlePublisherChange = (value) => {
    setPublisher(value);
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        publisher: value
      })
    );
  };

  const handleTrafficTypeChange = (value) => {
    setTrafficType(value);
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        trafficType: value
      })
    );
  };

  const handleDidnotReachCap = (value) => {
    setDidnotReachCap(value);
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        didnotReachCap: value
      })
    );
  };

  const handleDidnotGoLive = (value) => {
    setDidnotGoLive(value);
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        didnotGoLive: value
      })
    );
  };
  const handleTrafficPaused = (value) => {
    setTrafficPaused(value);
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        trafficPaused: value
      })
    );
  };

  const handleClearAll = () => {
    setQuery("");
    setSearchValue("");
    setPublished(false);
    setDraft(false);
    setIsLive(null);
    setPublisher("");
    setTagId("");
    setTag(null);
    setTrafficType(null);
    setDidnotReachCap(false);
    setDidnotGoLive(false);
    setTrafficPaused(false);
    stableDispatch(
      setSelectedFilter({
        query: "",
        publisher: "",
        isLive: null,
        tagId: "",
        published: false,
        draft: false,
        trafficType: "",
        didnotReachCap: false,
        didnotGoLive: false,
        trafficPaused: false
      })
    );
  };

  return (
    <div className="filter-box__container">
      <div className="filter-box">
        <div className="filter-box__controls">
          <div className="filter-box__searchbar">
            {/* <h4>Search Text</h4> */}
            <div className="filter-box__searchField">
              <TextField
                variant="standard"
                margin="normal"
                label="Type to search ..."
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleSearchButton();
                }}
                value={query}
                fullWidth
              />
            </div>
          </div>
        </div>
        <div></div>
        <div className="filter-box__options">
          {/* publisher select */}
          <div className="filter-box__options-select">
            <AutoCompleteSelect
              selected={publisher ? publisher : ""}
              data={publishers || []}
              handleChange={(value) => handlePublisherChange(value)}
              label={"Publisher"}
              showValueInLabel={true}
              enableMultiple={false}
              withDotsForStatus={true}
              cannotSelectDisabled={false}
            />
          </div>

          <div className="filter-box__options-select">
            <AutoCompleteSelect
              selected={trafficType ? trafficType : ""}
              data={trafficTypes || []}
              handleChange={(value) => handleTrafficTypeChange(value)}
              label={"Traffic Type"}
              showValueInLabel={true}
              enableMultiple={false}
              withDotsForStatus={true}
              cannotSelectDisabled={false}
            />
          </div>

          {/*  select */}
          <div className="filter-box__options">
            <div className="filter-box__options-select">
              <TextField
                variant="outlined"
                margin="normal"
                label="Pub Link Id"
                type="number"
                onChange={(e) => {
                  setTagId(e.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleSearchButton();
                }}
                value={tagId}
              />
            </div>
          </div>

          {!archive && (
            <div>
              <h4>Filter By Status</h4>
              <div className="filter-box__options-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={published}
                      onChange={() => handleChange("published")}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="cusDomain"
                    />
                  }
                  label="Published"
                />
              </div>
              <div className="filter-box__options-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={draft}
                      onChange={() => handleChange("draft")}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="cusDomain"
                    />
                  }
                  label="Draft"
                />
              </div>
              <div className="filter-box__options-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={is_live === true}
                      onChange={() => handleLiveChange("live")}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="cusDomain"
                    />
                  }
                  label="Is Live"
                />
              </div>
              <div className="filter-box__options-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={is_live === false}
                      onChange={() => handleLiveChange("notLive")}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="cusDomain"
                    />
                  }
                  label="Is Not Live"
                />
              </div>
              <div className="filter-box__options-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={didnotReachCap}
                      onChange={() => handleDidnotReachCap(!didnotReachCap)}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="cusDomain"
                    />
                  }
                  label="Didn't reach cap"
                />
              </div>
              <div className="filter-box__options-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={didnotGoLive}
                      onChange={() => handleDidnotGoLive(!didnotGoLive)}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="cusDomain"
                    />
                  }
                  label="Didn't go live(last 7days)"
                />
              </div>
              <div className="filter-box__options-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={trafficPaused}
                      onChange={() => handleTrafficPaused(!trafficPaused)}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="cusDomain"
                    />
                  }
                  label="Traffic paused"
                />
              </div>
            </div>
          )}
        </div>

        <div className="filter-box__button">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSearchButton}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleClearAll}
            startIcon={<ClearAllIcon />}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TargetFilterBox;
