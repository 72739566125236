import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTextInput } from "../../../hooks/useTextInput";
import { useMultipleSelect } from "../../../hooks/useMultipleSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { Platform } from "../../../services/Platform";
import { SearchEngine } from "../../../services/SearchEngine";
import { TagType } from "../../../services/TagType";
import { Provider } from "../../../services/Provider";
import { Publisher } from "../../../services/Publisher";
import { findObject } from "../../../helpers";
import { useLocation } from "react-router-dom";
import { getProviderLinkSelectedFilter } from "../../../reducers/providerLink/providerLink.selector";
import { setProviderLinkSelectedFilter } from "../../../reducers/providerLink/providerLink.action";

export const useProviderLinksFilters = ({
  redirectedProviderId,
  setRedirectedProviderId
}) => {
  const selectedFilter = useSelector(getProviderLinkSelectedFilter);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [query, handleQueryChange, setQuery] = useTextInput({
    initialValue: selectedFilter.query
  });
  const [search_id, handleSearchIdChange, setSearchId] = useTextInput({
    initialValue:
      searchParams.get("provider_link_id") || selectedFilter.search_id
  });

  const [isLive, setIsLive] = useState(selectedFilter.isLive);
  const [isLinked, setIsLinked] = useState(selectedFilter.isLinked);
  const [isReusable, setIsReusable] = useState(selectedFilter.isReusable);
  const [isNotLive, setIsNotLive] = useState(selectedFilter.isNotLive);
  const [isNotLinked, setIsNotLinked] = useState(selectedFilter.isNotLinked);
  const [isNotReusable, setIsNotReusable] = useState(
    selectedFilter.isNotReusable
  );

  const [didnotReachCap, setDidnotReachCap] = useState(
    selectedFilter.didnotReachCap
  );
  const [didnotGoLive, setDidnotGoLive] = useState(selectedFilter.didnotGoLive);
  const [trafficPaused, setTrafficPaused] = useState(
    selectedFilter.trafficPaused
  );

  function clearIsLive() {
    setIsLive(false);
    setIsNotLive(false);
  }

  function clearIsLinked() {
    setIsLinked(false);
    setIsNotLinked(false);
  }

  function clearIsReused() {
    setIsReusable(false);
    setIsNotReusable(false);
  }

  function clearDidnotReachCap() {
    setDidnotReachCap(false);
  }

  function clearDidnotGoLive() {
    setDidnotGoLive(false);
  }

  function clearTrafficPaused() {
    setTrafficPaused(false);
  }

  const {
    setData: setTagTypes,
    selectedData: selectedTagTypes,
    data: tagTypes,
    handleSelectedDataChange: handleTagTypeChange,
    removeSelectedDataFromList: removeTagTypeFromList,
    removeAll: removeAllTagTypes
  } = useMultipleSelect(selectedFilter.selectedTagTypes);

  const {
    setData: setPlatforms,
    selectedData: selectedPlatforms,
    data: platforms,
    handleSelectedDataChange: handlePlatformChange,
    removeSelectedDataFromList: removePlatformFromList,
    removeAll: removeAllPlatforms
  } = useMultipleSelect(selectedFilter.selectedPlatforms);

  const {
    setData: setSearchEngines,
    selectedData: selectedSearchEngines,
    data: searchEngines,
    handleSelectedDataChange: handleSearchEngineChange,
    removeSelectedDataFromList: removeSearchEngineFromList,
    removeAll: removeAllSearchEngines
  } = useMultipleSelect(selectedFilter.selectedSearchEngines);

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange,
    removeSelectedDataFromList: removeProviderFromList,
    removeAll: removeAllProviders
  } = useMultipleSelect(selectedFilter.selectedProviders);

  const {
    setData: setPublishers,
    selectedData: selectedPublishers,
    data: publishers,
    handleSelectedDataChange: handlePublisherChange,
    removeSelectedDataFromList: removePublisherFromList,
    removeAll: removeAllPublishers
  } = useMultipleSelect(selectedFilter.selectedPublishers);

  const {
    selectedData: selectedQualityScore,
    data: qualityScores,
    handleSelectedDataChange: handleQualityScoreChange,
    removeSearchEngineFromList: removeQualityScoreFromList,
    removeAll: removeAllQualityScores
  } = useMultipleSelect(selectedFilter.selectedQualityScore);

  const [filtersClearToggle, setFiltersClearToggle] = useState(false);

  function clearAllSelection() {
    setQuery("");
    setSearchId("");
    removeAllTagTypes();
    removeAllPlatforms();
    removeAllSearchEngines();
    removeAllProviders();
    removeAllPublishers();
    removeAllQualityScores();
    clearIsLive();
    clearIsLinked();
    clearIsReused();
    clearDidnotReachCap();
    clearDidnotGoLive();
    clearTrafficPaused();
    setFiltersClearToggle(!filtersClearToggle);

    // reset filter values in redux
    stableDispatch(
      setProviderLinkSelectedFilter({
        query: "",
        search_id: "",
        selectedProviders: [],
        selectedPublishers: [],
        selectedPlatforms: [],
        selectedSearchEngines: [],
        selectedTagTypes: [],
        selectedQualityScore: [],
        isLinked: false,
        isLive: false,
        isReusable: false,
        isNotLinked: false,
        isNotLive: false,
        isNotReusable: false,
        didnotReachCap: false,
        didnotGoLive: false,
        trafficPaused: false
      })
    );
    window.history.pushState("", "", "/advertisers/links");
  }

  let filterValues = {
    query,
    search_id,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedProviders,
    selectedPublishers,
    selectedQualityScore,
    isLive,
    isLinked,
    isReusable,
    isNotLinked,
    isNotLive,
    isNotReusable,
    didnotReachCap,
    didnotGoLive,
    trafficPaused
  };

  let selectionAddActions = {
    handleQueryChange,
    handleSearchIdChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    handlePublisherChange,
    handleQualityScoreChange,
    handleProviderChange,
    setIsLive,
    setIsLinked,
    setIsReusable,
    setIsNotLive,
    setIsNotLinked,
    setIsNotReusable,
    setSearchId,
    setDidnotReachCap,
    setDidnotGoLive,
    setTrafficPaused
  };

  let dataLists = {
    tagTypes,
    platforms,
    searchEngines,
    providers,
    publishers,
    qualityScores
  };

  const fetchPublishers = useCallback(() => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            publishers.push({ label: p.name, value: p.id });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPlatforms = useCallback(() => {
    Platform.fetchPlatforms({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.platforms;
          const platforms = [];
          for (let p of data) {
            platforms.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setPlatforms(platforms);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPlatforms]);

  const fetchSearchEngines = useCallback(() => {
    SearchEngine.fetchSearchEngines({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.search_engines;
          const searchEngines = [];
          for (let p of data) {
            searchEngines.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setSearchEngines(searchEngines);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setSearchEngines]);

  const fetchTagTypes = useCallback(() => {
    TagType.fetchTagTypes({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.tag_types;
          const tagTypes = [];
          for (let p of data) {
            tagTypes.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setTagTypes(tagTypes);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTagTypes]);

  useEffect(() => {
    fetchPlatforms();
    fetchSearchEngines();
    fetchTagTypes();
    fetchProviders();
    fetchPublishers();
  }, [
    fetchTagTypes,
    fetchSearchEngines,
    fetchPlatforms,
    fetchProviders,
    fetchPublishers
  ]);

  useEffect(() => {
    if (providers && providers.length > 0) {
      if (redirectedProviderId && findObject(providers, redirectedProviderId)) {
        handleProviderChange([redirectedProviderId]);
        setRedirectedProviderId(null);
        setFiltersClearToggle(!filtersClearToggle);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  return {
    clearAllSelection,
    filterValues,
    selectionAddActions,
    filtersClearToggle,
    dataLists,
    removeTagTypeFromList,
    removePlatformFromList,
    removeSearchEngineFromList,
    removeProviderFromList,
    removePublisherFromList,
    removeQualityScoreFromList
  };
};
