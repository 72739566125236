import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Prompt } from "react-router-dom";
import moment from "moment";

// material ui
import {
  Button,
  FormLabel,
  FormControlLabel,
  TextField,
  Divider,
  Switch,
  Radio,
  RadioGroup,
  FormControl,
  FormHelperText,
  Grid
} from "@material-ui/core";
import {
  Done as DoneIcon,
  OfflineBoltSharp as TargetIcon,
  PublishSharp as PublishIcon,
  CloseSharp as CloseIcon,
  Help as HelpIcon
} from "@material-ui/icons";

// custom hooks
import { useFormTouched } from "../../hooks/useFormTouched";
import { useModal } from "../../hooks/useModal";
import { useFetchPlatforms } from "./logics/fetchPlatforms.logic";
import { useFetchSearchEngines } from "./logics/fetchSearchEngine.logic";
import { useFetchTagTypes } from "./logics/fetchTagTypes.logic";
// import { useHandleFormValueChange } from "./logics/rule-block/handleFormValueChange.logic";

// components
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import AutoCompleteSelect from "../../components/AutoCompleteSelect/AutoCompleteSelect";
// import SimpleModal from "../../components/SimpleModal/SimpleModal";
import TargetRule from "./TargetRule";
import Alert from "../../components/AlertMessage/AlertMessage";

// services
import { AdvertiserLinkGroup } from "../../services/AdvertiserLinkGroup";
import { Setting } from "../../services/Settings";
import { ShareRevenue } from "../../services/ShareRevenue";

// reducers
import { getSettingsObject } from "../../reducers/setting/setting.selector";
import {
  getAdvertiserLinkGroupRules,
  getAdvertiserLinkGroupRulesError,
  getAdvertiserLinkGroup
} from "../../reducers/advertiserLinkGroup/selector";
import {
  updateAdvertiserLinkGroupRules,
  updateAdvertiserLinkGroupFormErrors,
  //   updateTargetingForm,
  clearAdvertiserLinkGroupForm
} from "../../reducers/advertiserLinkGroup/action";
import {
  updateDefaultSettings,
  updateSettingsObject
} from "../../reducers/setting/setting.action";

// layouts
// import History from "./layouts/History";
import FormAddtionalHeaders from "./layouts/FormAddtionalHeaders";
// import DuplicateAdvertiserLinksMsg from "./layouts/DuplicateAdvertiserLinksMsg";
import DuplicateLinksMsg from "./layouts/DuplicateLinksMsg";
import SavingMessage from "./layouts/SavingMessage";

// helper functions
import {
  formatTargetDataForSave,
  sumOfInputedWeights,
  getNumberOfActiveRules,
  ROUND_ROBIN_HELP_TEXT,
  WATERFALL_HELP_TEXT,
  calculateTotalDailyCap,
  updateTargetingRulesBeforeDispatch
} from "./helper/helper-functions";
import { TARGETING_TYPES } from "../../helpers/constant/types";
import { QualityScoreDropDown } from "../../helpers/constant/quality-score";
import { isArray } from "lodash";
import { useGetTagStats } from "./logics/getTagStats.logic";

export default function Index() {
  const history = useHistory();
  const settingsObject = useSelector(getSettingsObject);
  //   const [DEFAULT_DOMAIN, setDEFAULT_DOMAIN] = useState(
  //     () => settingsObject?.default_domain || ""
  //   );
  //   const [DEFAULT_FALLBACK, setDEFAULT_FALLBACK] = useState(
  //     () => settingsObject?.system_fallback || ""
  //   );

  const { platforms } = useFetchPlatforms();
  const { searchEngines } = useFetchSearchEngines();
  const { tagTypes } = useFetchTagTypes();

  const [platform, setPlatform] = useState("");
  const [searchEngine, setSearchEngine] = useState("");
  const [tagType, setTagType] = useState("");
  const [qualityScore, setQualityScore] = useState("");
  const [dailyCap, setDailyCap] = useState(0);
  // const [bufferPercentage, setBufferPercentage] = useState(20);
  const [description, setDescription] = useState("");

  const targetingRules = useSelector(getAdvertiserLinkGroupRules);
  const targetingRulesError = useSelector(getAdvertiserLinkGroupRulesError);
  const [oldRules, setOldRules] = useState(null);
  const initialTargeting = useSelector(getAdvertiserLinkGroup);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [rulesBlockNumber, setRulesBlockNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isWeightFieldTouched, setIsWeightFieldTouched] = useState(false);

  const [state, setState] = useState({});
  const [activate, setActivate] = useState(true);
  const [editing, setEditing] = useState(false);
  const [logicError, setLogicError] = useState(false);
  const [sendingFormData, setSendingFormData] = useState(false);

  //checking the url param to see if edit page is to be used
  const { pathname } = useLocation();
  const { id } = useParams() || null;
  const [targetingID, setTargetingID] = useState();
  const [showPrompt, setShowPrompt] = useState(true);
  const { publisherChannelStats } = useGetTagStats(id);

  //state for modal
  const [linkPropertiesModal, setLinkPropertyModal] = useState(false);

  // state for default revenue share
  const [defaultRevenueShare, setDefaultRevenueShare] = useState(0);

  //state for targeting rules that needs to be updated
  const [tempLinkProperty, setTempLinkProperty] = useState("");
  const [tempLinkValue, setTempLinkValue] = useState([]);
  const [tempTargetingRuleIds, setTempTargetingRuleIds] = useState([]);

  // to add or reset rule block
  const newRuleBlock = {
    daily_cap: 0,
    daily_frequency: 100,
    revenue_share_percentage: defaultRevenueShare,
    provider_details: [{ traffic: 100 }]
  };

  //state for popup message when weight is less than 100  when round robin is selected
  const [openWeightMessage, setOpenWeightMessage] = useState({
    modal: false,
    type: "draft"
  });

  const handleWeightMessageModalClose = () =>
    setOpenWeightMessage({ modal: false, type: "draft" });
  const [formOneErrors, setFormOneErrors] = useState({
    platform: false,
    searchEngine: false,
    qualityScore: false,
    tagType: false
  });

  // duplicated provider links in the current form page
  const [duplicatedProviderLinks, setDuplicatedProviderLinks] = useState([]);

  const [
    // duplicateAdLinksModal,
    openDuplicateLinksModal
    // closeDuplicateLinksModal
  ] = useModal(false);

  const cleanupFunction = useCallback(() => {
    stableDispatch(clearAdvertiserLinkGroupForm());
  }, [stableDispatch]);
  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction
  });

  //clearning up form touched when the page opens a new targeting ID
  useEffect(() => {
    clearFormTouched();
  }, [clearFormTouched, targetingID]);

  // fetch default revenue share
  useEffect(() => {
    (async () => {
      const revenueData = await ShareRevenue.getShareRevenue();
      setDefaultRevenueShare(revenueData?.data?.share_revenue);
    })();
  }, []);

  const fetchTargeting = useCallback(
    (id) => {
      setLoading(true);
      AdvertiserLinkGroup.fetchOne(id)
        .then((response) => {
          if (response.success) {
            let targeting = response.data.advertiser_link_group;
            let rules = targeting?.advertiser_link_group_rules || [];
            rules = rules.map((rule) => ({
              id: rule.id,
              daily_cap: rule.capacity,
              daily_frequency: rule.weight,
              revenue_share_percentage: rule.revenue_share,
              disabled: !rule.active,
              provider_details: [
                {
                  traffic: 100,
                  provider_id: rule.advertiser_id,
                  provider_link: rule.advertiser_link_id
                }
              ]
            }));
            if (!targeting) {
              history.push("/advertiser-link-group");
              toastr.warning("Oops", "No such targeting found!");
            }
            setOldRules(
              JSON.stringify(
                response.data.advertiser_link_group.advertiser_link_group_rules
              ) || []
            );
            setActivate(targeting?.active);
            setRulesBlockNumber((targeting?.rules || []).length);
            setLoading(false);
            stableDispatch(updateAdvertiserLinkGroupRules(rules));
            setPlatform(targeting?.platforms);
            setSearchEngine(targeting?.search_engines);
            setTagType(targeting?.tag_types);
            setQualityScore(targeting?.quality_scores);
            setDailyCap(targeting?.capacity);
            setState(targeting);
            // setBufferPercentage(targeting.buffer_percentage);
            setDescription(targeting.description);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          setLoading(false);
        });
    },
    [stableDispatch, history]
  );
  //create and dispatch error fields equivalent to targeting rules length for ruleset Form
  useEffect(() => {
    const formErrors = [];
    for (let j = 0; j < targetingRules.length; j++) {
      formErrors.push({
        dailyCap: false,
        trafficOne: false,
        providerOne: false,
        providerOneLink: false,
        dailyFrequency: false,
        revenueSharePercentage: false,
        weightPercentage: false
      });
    }
    stableDispatch(updateAdvertiserLinkGroupFormErrors(formErrors));
  }, [targetingRules.length, targetingRules, stableDispatch]);

  //changing rules blockset when targeting rules length changes
  useEffect(() => {
    setRulesBlockNumber(targetingRules.length);
  }, [targetingRules.length]);

  //confirming if user wants to leave
  useEffect(() => {
    if (id && pathname.includes("edit")) {
      setEditing(true);
      setTargetingID(id);
      fetchTargeting(id);
    } else {
      setEditing(false);
      setLoading(false);
    }
  }, [stableDispatch, fetchTargeting, id, pathname]);

  const validateForm1 = () => {
    let errorFields = [];

    if (!platform) {
      errorFields.push("platform");
    }

    if (!searchEngine) {
      errorFields.push("searchEngine");
    }
    if (!tagType) {
      errorFields.push("tagType");
    }
    if (!qualityScore) {
      errorFields.push("qualityScore");
    }

    // if (!bufferPercentage || Number.isNaN * bufferPercentage) {
    //   errorFields.push("buffer_percentage");
    // }

    if (!errorFields.length > 0) {
      setFormOneErrors({
        platform: false,
        searchEngine: false,
        qualityScore: false,
        tagType: false
      });
      return true;
    } else {
      let errors = {};
      errorFields.forEach((field) => {
        errors[field] = true;
      });

      setFormOneErrors((formOneErrors) => {
        return {
          ...formOneErrors,
          ...errors
        };
      });
      return false;
    }
  };

  //validating the values of the ruleset Form
  const validateForm2 = () => {
    let isValid = true;
    let weightsValid = true;
    let limitlessDailyCapExists = true;
    const formErrors = [...targetingRulesError];
    let providerLinksSelected = {};

    //validate if targeting Rules was create
    if (targetingRules.length === 0) {
      isValid = false;
      toastr.error("Oops", "Please add rules!!!");
    }

    for (let i = 0; i < targetingRules.length; i++) {
      let rule = targetingRules[i];
      let ruleDisabled = rule?.disabled || false;
      let formErrorObject = formErrors[i];
      if (
        rule.daily_cap === "" ||
        isNaN(rule.daily_cap) ||
        parseInt(rule.daily_cap) < 0
      ) {
        isValid = false;
        formErrorObject.dailyCap = true;
      }

      if (state.targeting === TARGETING_TYPES.WATERFALL) {
        if (!limitlessDailyCapExists) {
          formErrorObject.dailyCap = true;
        }
      }

      //validating that weights are valid
      if (state.targeting === TARGETING_TYPES.ROUND_ROBIN) {
        if (
          !ruleDisabled &&
          (rule.daily_frequency <= 0 || rule.daily_frequency > 100)
        ) {
          isValid = false;
          formErrorObject.dailyFrequency = true;
        }
        if (
          rule.daily_frequency === undefined ||
          rule.daily_frequency === null ||
          isNaN(parseFloat(rule.daily_frequency))
        ) {
          isValid = false;
          formErrorObject.dailyFrequency = true;
        }
        if (!weightsValid) {
          formErrorObject.dailyFrequency = true;
        }
      }

      if (!rule.provider_details || rule.provider_details.length === 0) {
        isValid = false;
        formErrorObject.trafficOne = true;
      } else {
        if (rule.provider_details.length === 2) {
          //case when two providers are selected
          if (
            !rule.provider_details[0].traffic ||
            isNaN(rule.provider_details[0].traffic) ||
            parseInt(rule.provider_details[0].traffic) <= 0
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
          if (
            !rule.provider_details[0].provider_id ||
            rule.provider_details[0].provider_id === ""
          ) {
            isValid = false;
            formErrorObject.providerOne = true;
          }
          let providerOneLink = rule.provider_details[0].provider_link;
          if (providerOneLink === "") {
            isValid = false;
            formErrorObject.providerOneLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerOneLink] =
              providerLinksSelected.hasOwnProperty(providerOneLink)
                ? providerLinksSelected[providerOneLink] + 1
                : 1;
          }
          if (
            !rule.provider_details[1].traffic ||
            isNaN(rule.provider_details[1].traffic) ||
            parseInt(rule.provider_details[1].traffic) <= 0
          ) {
            isValid = false;
            formErrorObject.trafficTwo = true;
          }
          if (
            !rule.provider_details[1].provider_id ||
            rule.provider_details[1].provider_id === ""
          ) {
            isValid = false;
            formErrorObject.providerTwo = true;
          }
          let providerTwoLink = rule.provider_details[1].provider_link;
          if (providerTwoLink === "") {
            isValid = false;
            formErrorObject.providerTwoLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerTwoLink] =
              providerLinksSelected.hasOwnProperty(providerTwoLink)
                ? providerLinksSelected[providerTwoLink] + 1
                : 1;
          }
          if (
            parseInt(rule.provider_details[1].traffic) +
              parseInt(rule.provider_details[0].traffic) !==
            100
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
        } else {
          //case when only one provider is selected
          if (
            !rule.provider_details[0].traffic ||
            isNaN(rule.provider_details[0].traffic) ||
            parseInt(rule.provider_details[0].traffic) !== 100
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
          if (
            !rule.provider_details[0].provider_id ||
            rule.provider_details[0].provider_id === ""
          ) {
            isValid = false;
            formErrorObject.providerOne = true;
          }
          let providerOneLink = rule.provider_details[0].provider_link;
          if (providerOneLink === "") {
            isValid = false;
            formErrorObject.providerOneLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerOneLink] =
              providerLinksSelected.hasOwnProperty(providerOneLink)
                ? providerLinksSelected[providerOneLink] + 1
                : 1;
          }
        }
      }
    }

    // validating for duplicates in provider links for rules that are not disabled
    let duplicatedProviderLinks = [];
    let providerLinksSelectedKeys = Object.keys(providerLinksSelected);
    for (let i = 0; i < providerLinksSelectedKeys.length; i++) {
      let linkToCheck = providerLinksSelectedKeys[i];
      if (providerLinksSelected[linkToCheck] > 1) {
        duplicatedProviderLinks.push(linkToCheck);
        isValid = false;
        setLogicError(true);
      }
    }

    setDuplicatedProviderLinks(duplicatedProviderLinks);
    dispatch(updateAdvertiserLinkGroupFormErrors(formErrors));
    return isValid;
  };

  /**
   *  This function is currently not used as the feature was change to let admin use same link
   *  @returns isValid;
   *  @description validates if the advertiser links selected here is duplicate with any publisher  in the backend
   */
  const validateAdvertiserLinks = async () => {
    return true;
  };

  /**
   * @description opens the modal showing the duplicated advertiser links in other tags using the value saved in state
   */
  const openDuplicatedAdvertiserLinksModal = () => {
    openDuplicateLinksModal();
  };

  //   const closeDuplicatedAdvertiserLinksModal = () => {
  //     setDuplicatedProviderLinksDB([]);
  //     closeDuplicateLinksModal();
  //   };

  useEffect(() => {
    if (!settingsObject?.default_domain || !settingsObject?.system_fallback) {
      Setting.fetchSettings()
        .then((response) => {
          console.log(response);
          if (response.success) {
            const settings = response.data?.settings || [];
            let settingsObject = {};
            settings.forEach((setting) => {
              settingsObject[setting.key] = setting.value;
            });
            stableDispatch(updateSettingsObject(settingsObject));
            stableDispatch(updateDefaultSettings(settings));
            // setDEFAULT_DOMAIN(settingsObject?.default_domain);
            // setDEFAULT_FALLBACK(settingsObject?.system_fallback);
            if (
              !settingsObject?.default_domain ||
              !settingsObject?.system_fallback
            ) {
              toastr.warning("Oops!", "Default Domain not set");
              history.push("/settings");
            }
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          toastr.error("Opps", "Cannot load default settings!!!");
          console.trace(error.message);
        });
    }
  }, [history, settingsObject, stableDispatch]);

  const addMoreRulesBlock = () => {
    let currentTargetingRules = [...targetingRules];
    currentTargetingRules.push(newRuleBlock);
    dispatch(updateAdvertiserLinkGroupRules(currentTargetingRules));
  };

  const handleLinkPropertiesChange = (property, value) => {
    switch (property) {
      case "platform":
        handlePlatformChange(value);
        break;

      case "searchEngine":
        handleSearchEngineChange(value);
        break;

      case "tagType":
        handleTagTypeChange(value);
        break;

      case "qualityScore":
        handleQualityScoreChange(value);
        break;

      default:
        break;
    }
  };

  const checkForDisabledRules = (property, oldValue, value) => {
    let showModalWarning = false;
    if (oldValue !== null && Array.isArray(oldValue)) {
      let diff = oldValue.filter((item) => !value.includes(item));
      let tempTargetingRuleIds = [];

      if (diff.length) {
        switch (property) {
          case "platform":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].platform_id)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          case "searchEngine":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].search_engine_id)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          case "tagType":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].tag_type_id)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          case "qualityScore":
            tempTargetingRuleIds = targetingRules
              .filter((rule) =>
                diff.includes(rule.provider_details[0].quality_score)
              )
              .map((rule) => rule.provider_details[0].provider_link);
            break;
          default:
            break;
        }
      }
      tempTargetingRuleIds.length > 0
        ? (showModalWarning = true)
        : (showModalWarning = false);
      setTempTargetingRuleIds(tempTargetingRuleIds);
      setLinkPropertyModal(showModalWarning);
    }

    if (!showModalWarning) {
      handleLinkPropertiesChange(property, value);
    } else {
      setTempLinkProperty(property);
      setTempLinkValue(value);
    }
  };

  const resetLinkPropertyChange = () => {
    setTempLinkProperty("");
    setTempLinkValue([]);
    setTempTargetingRuleIds([]);
    setLinkPropertyModal(false);
  };

  const confirmLinkPropertyChange = () => {
    // disable targeting rules after link property removal
    targetingRules.map((rule) => {
      if (
        tempTargetingRuleIds.includes(rule.provider_details[0].provider_link)
      ) {
        rule.disabled = true;
      }
      return rule;
    });
    setDailyCap(calculateTotalDailyCap(targetingRules));
    const updatedTargetingRules =
      updateTargetingRulesBeforeDispatch(targetingRules);
    stableDispatch(updateAdvertiserLinkGroupRules(updatedTargetingRules));
    handleLinkPropertiesChange(tempLinkProperty, tempLinkValue);
    resetLinkPropertyChange();
  };

  const handlePlatformChange = (value) => {
    setPlatform(isArray(value) && value.length > 0 ? value : null);
    handleFormTouched();
  };
  const handleSearchEngineChange = (value) => {
    setSearchEngine(isArray(value) && value.length > 0 ? value : null);
    handleFormTouched();
  };
  const handleTagTypeChange = (value) => {
    setTagType(isArray(value) && value.length > 0 ? value : null);
    const TYPE_IN_ID =
      tagTypes.find((x) => x.label === "TYPE-IN")?.value || null;
    if (
      value &&
      isArray(value) &&
      value.length === 1 &&
      value.includes(TYPE_IN_ID)
    ) {
      setState({ ...state, useHighPayingKeywords: true });
    } else {
      setState({ ...state, useHighPayingKeywords: false });
    }
    handleFormTouched();
  };
  const handleQualityScoreChange = (value) => {
    setQualityScore(isArray(value) && value.length > 0 ? value : null);

    if (!editing) {
      if (value.length) {
        if (value.includes("low") || value.includes("mid"))
          setState({ ...state, warmupMode: false });
        else setState({ ...state, warmupMode: true });
      } else {
        setState({ ...state, warmupMode: false });
      }
    }
    handleFormTouched();
  };

  //   const handleDailyCapChange = (value) => {
  //     setDailyCap(value);
  //     handleFormTouched();
  //   };

  const handleChange = (event) => {
    handleFormTouched();
    setFormOneErrors({
      id: false,
      platform: false,
      search_engine: false,
      quality_score: false,
      tag_type: false
      // buffer_percentage: false
    });

    //not chaning values if negative values are entered
    //for OID and Client ID
    //if (["oID", "clientID"].includes(event.target.name))
    if (["id"].includes(event.target.name)) {
      if (parseInt(event.target.value) < 0) {
        setFormOneErrors({
          ...formOneErrors,
          [event.target.name]: true
        });
        return;
      }
    }

    if (event.target.name === "description") {
      setDescription(event.target.value);
    }
    // if (event.target.name === "buffer_percentage") {
    //   setBufferPercentage(event.target.value);
    // }
  };

  const handleActivate = (event) => {
    handleFormTouched();
    setActivate(event.target.checked);
  };

  //type = "draft" or "publish"
  const handleSave = async (type) => {
    setLogicError(false);
    //validating forms
    let isFormOneValid = validateForm1();
    let isFormTwoValid = validateForm2();
    if (!isFormOneValid || !isFormTwoValid) {
      toastr.info("Please check all the required field values!");
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth"
      });
      return;
    }
    let isAdvertiserLinksValid = await validateAdvertiserLinks();
    if (!isAdvertiserLinksValid) {
      openDuplicatedAdvertiserLinksModal();
      return;
    }

    setShowPrompt(false);
    clearFormTouched();
    const data = getFormData(type);
    data["rules"] = formatTargetDataForSave(
      targetingRules,
      null,
      null,
      activate
    );

    AdvertiserLinkGroup.save(data)
      .then((response) => {
        if (response.success) {
          if (type === "DRAFT") {
            // set edit to true, add targeting id and then update
            setEditing(true);
            let saved = response.data.advertiser_link_group;
            history.push("/advertiser-link-group/edit/" + saved.id);
            toastr.success(
              "Success",
              "This Advertiser link group is ready to be published!"
            );
          } else {
            dispatch(clearAdvertiserLinkGroupForm());
            history.push("/advertiser-link-group");
            toastr.success("Success", "Advertiser Link Group published!");
          }
          setSendingFormData(false);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        setSendingFormData(false);
      });
  };

  //type = "draft" or "publish"
  const handleUpdate = async (type) => {
    setLogicError(false);
    //validating forms
    let isFormOneValid = validateForm1();
    let isFormTwoValid = validateForm2();
    if (!isFormOneValid || !isFormTwoValid) {
      toastr.info("Please check all the required field values!");
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth"
      });
      return;
    }
    let isAdvertiserLinksValid = await validateAdvertiserLinks();
    if (!isAdvertiserLinksValid) {
      openDuplicatedAdvertiserLinksModal();
      return;
    }
    setShowPrompt(false);
    clearFormTouched();
    const data = getFormData(type);
    data["rules"] = formatTargetDataForSave(
      targetingRules,
      null,
      null,
      activate
    );

    AdvertiserLinkGroup.update(state.id, data)
      .then((response) => {
        if (response.success) {
          if (type === "DRAFT") {
            setEditing(true);
            window.location.reload();
            toastr.success(
              "Success",
              "This Advertiser Link Group is ready to be published!"
            );
          } else {
            dispatch(clearAdvertiserLinkGroupForm());
            history.push("/advertiser-link-group");
            toastr.success("Success", "Advertiser Link Group published!");
          }
          setSendingFormData(false);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        setSendingFormData(false);
      });
  };

  const getFormData = (type) => {
    const data = {
      active: activate,
      description: description,
      platforms: platform || null,
      search_engines: searchEngine || null,
      tag_types: tagType || null,
      quality_scores: qualityScore || null,
      published: type === "PUBLISHED"
      // buffer_percentage: bufferPercentage
    };
    return data;
  };

  /**
   * Returns a Boolean Value on whether to open or not the modal for weight<100 msg!
   *
   * @returns [True] if the follwing conditions are met
   *  a) The Targeting is of Round Robin Type
   *  b) If atleast one of the rule is not inactive (one rule exists such that rule.disabled === false)
   *  c) Sum is less than 100
   */
  function shouldOpenWeightMessage() {
    //state to track if we should open the popup message for weight
    let openMessage = false;
    let openMessageConditions = {
      IS_ROUND_ROBIN: state.targeting === TARGETING_TYPES.ROUND_ROBIN,
      ACTIVE_RULES_EXIST: getNumberOfActiveRules(targetingRules) > 0
    };
    if (
      openMessageConditions.IS_ROUND_ROBIN &&
      openMessageConditions.ACTIVE_RULES_EXIST
    ) {
      let { sum } = sumOfInputedWeights(targetingRules);
      if (sum < 100) {
        openMessage = true;
      }
    }
    return openMessage;
  }

  const handleFormButtonClick = (type) => {
    let openMessage = shouldOpenWeightMessage();
    if (openMessage) {
      setOpenWeightMessage({
        modal: true,
        type
      });
    } else {
      !editing ? handleSave(type) : handleUpdate(type);
    }
  };

  //   const handleFormSubmit = (type) => {
  //     !editing ? handleSave(type) : handleUpdate(type);
  //   };

  if (loading) return <LoadingSpinner />;

  // const getDefaultRevenueShare = async () => {
  //     return await ShareRevenue.getShareRevenue();
  // };

  return (
    <div className="targeting-form">
      <div className="targeting-form__header">
        <ContentHeader
          icon={<TargetIcon fontSize="inherit" color="primary" />}
          title="Advertiser link Group"
          additionalComponent={
            <FormAddtionalHeaders
              targetingID={targetingID}
              publisherChannelStats={publisherChannelStats}
            />
          }
        />
      </div>
      {!sendingFormData ? (
        <>
          <div className="targeting-form__publisher-section">
            <div className="targeting-form__publisher-section__top">
              <FormControl>
                <TextField
                  label="Advertiser Link Group ID"
                  variant="outlined"
                  disabled
                  name="id"
                  value={state.id == null ? "" : state.id}
                  onChange={handleChange}
                  error={formOneErrors.id}
                  autoFocus={formOneErrors.id}
                  type="number"
                />
                {formOneErrors.id && (
                  <FormHelperText className="error-text">
                    Positive Number Required
                  </FormHelperText>
                )}
              </FormControl>

              <div className="targeting-form__active">
                <FormControlLabel
                  control={
                    <Switch
                      checked={activate}
                      onChange={handleActivate}
                      name="checkedA"
                    />
                  }
                  label="Active"
                />
              </div>
            </div>
            <div className="targeting-form__publisher-section__upper-mid">
              <Grid container spacing={3}>
                <Grid item md={9}>
                  <Grid
                    container
                    spacing={4}
                    className="link__properties-container"
                  >
                    <Grid item lg={1} md={3} sm={6} xs={12}>
                      <div
                        style={{
                          marginTop: "17px",
                          color: "#949494"
                        }}
                      >
                        <label>Link Properties: </label>
                      </div>
                    </Grid>
                    <Grid item lg={11} md={9} xs={12} sm={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <AutoCompleteSelect
                            name="platform"
                            selected={platform}
                            data={platforms || []}
                            handleChange={(value) =>
                              checkForDisabledRules("platform", platform, value)
                            }
                            label={"Platform"}
                            showValueInLabel={true}
                            enableMultiple={true}
                            withDotsForStatus={false}
                            error={formOneErrors.platform}
                          />
                          {formOneErrors.platform && (
                            <FormHelperText>
                              <span className="error-text">
                                Platform is Required!
                              </span>
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <AutoCompleteSelect
                            name="searchEngine"
                            selected={searchEngine}
                            data={searchEngines || []}
                            handleChange={(value) =>
                              checkForDisabledRules(
                                "searchEngine",
                                searchEngine,
                                value
                              )
                            }
                            label={"Search Engine"}
                            showValueInLabel={true}
                            enableMultiple={true}
                            withDotsForStatus={false}
                            error={formOneErrors.searchEngine}
                          />
                          {formOneErrors.searchEngine && (
                            <FormHelperText>
                              <span className="error-text">
                                Search engine is Required!
                              </span>
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <AutoCompleteSelect
                            name="tagType"
                            selected={tagType}
                            data={tagTypes}
                            handleChange={(value) =>
                              checkForDisabledRules("tagType", tagType, value)
                            }
                            label={"Traffic Type"}
                            showValueInLabel={true}
                            enableMultiple={true}
                            withDotsForStatus={false}
                            error={formOneErrors.tagType}
                          />
                          {formOneErrors.tagType && (
                            <FormHelperText>
                              <span className="error-text">
                                Tag type is Required!
                              </span>
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <AutoCompleteSelect
                            name="qualityScore"
                            selected={qualityScore}
                            data={QualityScoreDropDown || []}
                            handleChange={(value) =>
                              checkForDisabledRules(
                                "qualityScore",
                                qualityScore,
                                value
                              )
                            }
                            label={"Quality Score"}
                            showValueInLabel={false}
                            enableMultiple={true}
                            withDotsForStatus={false}
                            error={formOneErrors.qualityScore}
                          />
                          {formOneErrors.qualityScore && (
                            <FormHelperText>
                              <span className="error-text">
                                Quality score is Required!
                              </span>
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              label="Daily Cap"
                              variant="outlined"
                              name="dailyCap"
                              value={dailyCap}
                              onChange={handleChange}
                              error={formOneErrors.dailyCap}
                              autoFocus={formOneErrors.dailyCap}
                              type="number"
                              disabled
                            />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              label="Buffer (%)"
                              variant="outlined"
                              name="buffer_percentage"
                              value={bufferPercentage}
                              onChange={handleChange}
                              error={formOneErrors.buffer_percentage}
                              autoFocus={formOneErrors.buffer_percentage}
                              type="number"
                            />
                          </FormControl>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={3} sm={12}>
                  <div
                    className="targeting-form__update-info"
                    style={{
                      textAlign: "left"
                    }}
                  >
                    <p style={{ textAlign: "left" }}>
                      Status: {state.published ? "PUBLISHED" : "DRAFT"}
                    </p>
                    <p>
                      Last Updated (UTC) :<br />
                      {state.updated_at &&
                        moment(state.updated_at).utc().format("lll")}
                    </p>
                    <p>
                      Last Published (UTC) : <br />
                      {state.published_at &&
                        moment(state.published_at).utc().format("lll")}
                    </p>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <div className="targeting-form__notes">
                    <TextField
                      label="Description"
                      multiline
                      minRows={2}
                      variant="outlined"
                      name="description"
                      value={description}
                      onChange={handleChange}
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            {/* <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.useClickId}
                    onChange={handleChange}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="useClickId"
                  />
                }
                label="Use Click ID"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.passReferrer}
                    onChange={handleChange}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="passReferrer"
                  />
                }
                label="Pass Referrer"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.warmupMode}
                    onChange={handleChange}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="warmupMode"
                  />
                }
                label="WarmUp Mode"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.temporarilyDisableFilters}
                    onChange={handleChange}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="temporarilyDisableFilters"
                  />
                }
                label="Disable filters for first 30 searches"
              />
            </div> */}

            {/* <div className="targeting-form__publisher-section__bottom flow-content--small">
              <div className="targeting-form__tag">
                <div className="targeting-form__tag__link-row">
                  <FormControl fullWidth>
                    <TextField
                      required
                      disabled
                      variant="outlined"
                      label="Link"
                      value={targetLink}
                      error={formOneErrors.targetLink}
                      autoFocus={formOneErrors.targetLink}
                    />
                    {formOneErrors.targetLink && (
                      <FormHelperText className="error-text">
                        Link not generated
                      </FormHelperText>
                    )}
                  </FormControl>
                  {editing && (
                    <div className="button-group">
                      <Tooltip
                        title="Copy Link"
                        onClick={() => handleCopyToClipboard(targetLink)}
                      >
                        <IconButton aria-label="history" className="green-icon">
                          <AssignmentTurnedInIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Check Link"
                        onClick={() => handleCheckTag(targetLink)}
                      >
                        <IconButton aria-label="history" className="copy-icon">
                          <OpenInBrowserIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <FormControlLabel
                  control={
                    <InfoSharp
                      className="info-icon"
                      style={{ color: "blue" }}
                      fontSize="large"
                    />
                  }
                  label="Link is auto generated based on input and is read-only."
                />
              </div>
            </div> */}
          </div>

          {!(platform && searchEngine && tagType && qualityScore) ? (
            <div className="spacing__top">
              <Alert
                type="warning"
                message="Please select all the Link Properties before assigning the rules."
              />
            </div>
          ) : (
            false
          )}

          <div>
            <Divider />
            {duplicatedProviderLinks.length > 0 && (
              <DuplicateLinksMsg
                duplicatedIds={duplicatedProviderLinks || []}
              />
            )}
            <div className="target__section">
              <FormLabel component="legend">Targeting</FormLabel>
              <RadioGroup
                aria-label="targeting"
                name="targeting"
                value={state.targeting}
                onChange={handleChange}
                className="radio-section"
              >
                <div className="targeting-option">
                  <FormControlLabel
                    value={TARGETING_TYPES.ROUND_ROBIN}
                    control={<Radio />}
                    label="Round Robin"
                  />
                  <InfoToolTip
                    title={ROUND_ROBIN_HELP_TEXT}
                    className="targeting-option__info"
                  >
                    <div className="gray-icon ">
                      <HelpIcon fontSize="small" />
                    </div>
                  </InfoToolTip>
                </div>
                <div className="targeting-option">
                  <FormControlLabel
                    value={TARGETING_TYPES.WATERFALL}
                    control={<Radio />}
                    label="Waterfall"
                  />
                  <InfoToolTip
                    title={WATERFALL_HELP_TEXT}
                    className="targeting-option__info"
                  >
                    <div className="gray-icon ">
                      <HelpIcon fontSize="small" />
                    </div>
                  </InfoToolTip>
                </div>
              </RadioGroup>
            </div>
            {targetingRules && (
              <TargetRule
                editing={editing}
                targeting={initialTargeting}
                targeting_type={state.targeting}
                addMoreRulesBlock={addMoreRulesBlock}
                rulesBlockNumber={rulesBlockNumber}
                logicError={logicError}
                oldRules={oldRules}
                targetingID={targetingID}
                targetingRules={targetingRules}
                targetingRulesError={targetingRulesError}
                handleFormTouched={handleFormTouched}
                linkStatus={activate}
                targetingLinkStatus={state.status}
                platform={platform}
                searchEngine={searchEngine}
                tagType={tagType}
                qualityScore={qualityScore}
                dailyCap={dailyCap}
                publisherChannelStats={publisherChannelStats}
                setDailyCap={setDailyCap}
                isWeightFieldTouched={isWeightFieldTouched}
                setIsWeightFieldTouched={setIsWeightFieldTouched}
                defaultRevenueShare={defaultRevenueShare}
              />
            )}

            <div className="footer-section">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleFormButtonClick("DRAFT")}
                startIcon={<DoneIcon />}
              >
                Save
              </Button>
              <Button
                variant="contained"
                className="save-publish"
                color="primary"
                onClick={() => handleFormButtonClick("PUBLISHED")}
                startIcon={<PublishIcon />}
              >
                Save & Publish
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push("/advertiser-link-group");
                }}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
            </div>
          </div>
        </>
      ) : (
        <SavingMessage />
      )}

      {openWeightMessage.modal && (
        <ConfirmBox
          message="Please note that Total weight is less than 100%."
          confirmAction={handleWeightMessageModalClose}
          hideCloseButton={true}
          affirmativeText="OK"
        />
      )}
      <Prompt
        when={
          showPrompt && formTouched.current
          // settingsObject?.default_domain !== undefined &&
          // settingsObject?.default_fallback !== undefined
        }
        message="Are you sure you want to leave?"
      />
      {linkPropertiesModal && (
        <ConfirmBox
          message="All rulesets related to this link property will be disabled. Are you sure to remove the link property?"
          confirmAction={confirmLinkPropertyChange}
          denyAction={resetLinkPropertyChange}
          affirmativeText="Proceed"
          negativeText="Cancel"
        />
      )}
    </div>
  );
}
